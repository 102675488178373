/* global HTMLElement, window, Headers, fetch, Flickity */

class QuizQuestions extends HTMLElement {


	constructor() {
		
		super();

		// Promises of answers that are being stored
		this._storeAnswerPromises = [];

		// Array of all questions that belong to this set
		this._questions = [];
		
		this._addAnswerSelectListener();
		this._addRegisterQuestionListener();

		this._addNextQuestionListener();

	}


	connectedCallback() {

		this._url = this.getAttribute('endpoint');
		if (!this._url) {
			console.error('QuizQuestions: Missing endpoint');
		}

		// Init slideshow – with a delay on Mobile Safari (see comment at _initSlideshow)
		/*const userAgent = window.navigator.userAgent;
		if (/iPad/i.test(userAgent) || /iPhone/i.test(userAgent)) {
			console.log('QuizQuestions: You\'re on a mobile Apple device.');
			setTimeout(() => this._initSlideshow(), 200);
		}
		else*/ this._initSlideshow();

	}


	/**
	* Stores answer on server. 
	*/
	_storeAnswer(answerId) {

		const headers = new Headers();
		headers.append('accept', 'application/json');

		const promise = fetch(this._url + answerId, {
			  headers		: headers
			, credentials	: 'same-origin'
		})
		.then((resp) => {
			return resp.status === 200 ? resp.json() : Promise.reject(new Error(`Could not fetch data from server, status is ${ resp.status }`));
		}, (err) => {
			return Promise.reject(err);
		})
		.then((json) => {
			this._storeAnswerPromises.splice(this._storeAnswerPromises.indexOf(promise), 1);
			console.log('QuizQuestions: Answer stored, promises are %o, data is %o', this._storeAnswerPromises, json);
		}, (err) => {
			this._displayError();
			console.error(`QuizQuestions: Could not store answer ${ answerId }, got error ${err.message}`);
			this.querySelector('.js-question-error-message').style.display = 'block';
		});
		

		return promise;
		
		// Test if button waits
		/*const testPromise = new Promise((resolve) => {
			console.log('QuizQuestions: Promise created');
			setTimeout(() => {
				console.log('QuizQuestions: Timeout done; promises are %o', this._storeAnswerPromises);
				const index = this._storeAnswerPromises.indexOf(testPromise);
				console.log('QuizQuestions: Remove promise %o', index);
				this._storeAnswerPromises.splice(index, 1);
				resolve();
			}, 5000);
		});
		return testPromise;*/

	}


	/**
	* Displays «Answer could not be saved» error
	*/
	_displayError() {
		// Display error
		this.querySelector('.js-question-error-message').style.display = 'block';

		// Adjust height of slideshow
		this._flickity.resize();
	}

	_hideError() {
		this.querySelector('.js-question-error-message').style.display = 'none';
	}


	/**
	* Public method; returns a promise that will resolve when all storePromises are resolved.
	*/
	waitForStoreAnswerPromises() {
		console.log('QuizQuestions: Wait for promises %o', this._storeAnswerPromises);
		if (!this._storeAnswerPromises.length) return Promise.resolve();
		return Promise.all(this._storeAnswerPromises);
	}


	/**
	* quiz-question will fire register-question when it's attached to the DOM. Listen to the event, 
	* then register myself at the quiz-question so that it can access waitForStoreAnswerPromises
	* when user goes to next chapter.
	*/
	_addRegisterQuestionListener() {
		this.addEventListener('register-question', (ev) => {
			// Make myself available to question: Needed to wait for promises to resolve
			ev.detail.element.registerQuestionSet(this);
		});
	}


	/**
	* User clicked «Next question» button: quiz-question will fire 'next-question'
	*/
	_addNextQuestionListener() {

		this.addEventListener('next-question', () => {
	
			// Errors always relate to one question, not the whole set: Hide it.
			this._hideError();

			console.log('QuizQuestions: Go to next question');
			// Go to next slide
			this._flickity.next();

		});

	}




	/**
	* Inits the Flickity slideshow. We cannot use regular HTML initialization as Mobile Safari 
	* fucks things up (as usual): When loading the page for the very first time in a new tab, 
	* the slideshow has no height and is invisible. 
	* See https://github.com/joinbox/bodyshop-learning/issues/13
	* 
	*/
	_initSlideshow() {
		
		this._flickity = new Flickity( '.js-questions-slideshow', {
			cellAlign			: 'left'
			, contain			: true
			, adaptiveHeight	: true
			//, imagesLoaded		: true
			, draggable			: false
			, prevNextButtons	: false
			, pageDots			: false 
		});

		console.log('QuizQuestions: Slideshow %o initialized', this._flickity);

	}


	/**
	* quiz-answer fires select-answer whenever answer is selected. Store selected answer on server.
	* User should not leave page (by clicking «Next chapter») until all promises are resolved – 
	* therefore store those.
	*/
	_addAnswerSelectListener() {

		this.addEventListener('select-answer', (ev) => {
			const promise = this._storeAnswer(ev.detail.answerId);
			this._storeAnswerPromises.push(promise);
			console.log('QuizQuestions: Addded promise %o to _storeAnswerPromises %o', promise, this._storeAnswerPromises);
		});

	}




}

window.customElements.define('quiz-questions', QuizQuestions);